import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = [
  "| 스테이지파이브, 15일 아이폰13 시리즈 <사전예약 알림 신청 이벤트> 진행",
  "| 카카오톡 핀다이렉트샵 채널추가만 해도 무조건 아이폰13 3만원 할인권 지급",
  "| 사전예약 후 출고 지연 시 1일 당 1만원 파격 보상 등 아이폰 유저 취향 저격 혜택이 풍성",
]

const content = () => (
  <div>
    <br />
    <div className="descImage">
      <div className="img">
        <img src="/images/press/22/wuOWaCgG.png" style={{ maxWidth: "200px", margin: "auto" }} alt="Figure1" />
      </div>
      <div className="desc">
        △ 스테이지파이브(대표 서상원)가 15일 오전11시부터 아이폰13 사전예약 알림 신청 이벤트를 진행한다.
        <br />총 6가지 혜택의 사전 예약 알림 신청은 ‘핀다이렉트샵’(https://www.pindirectshop.com)에서 가능하다.
      </div>
    </div>
    <p>
      <br />
      <br />
      아이폰의 계절이 돌아온다. 아이폰13 시리즈의 공개에 발맞춰 스테이지파이브(대표 서상원)가 15일(수) 오전 11시부터
      ‘핀다이렉트샵’을 통해 아이폰13 사전예약 알림 신청 이벤트를 진행한다.
      <br />
      <br />
      사전예약 알림 신청 이벤트는 아이폰13 사전예약 개시 시점에 즉시 안내 받길 원하는 이들을 위한 스테이지파이브의 알림 서비스 및
      경품 특전 행사이다.
      <br />
      <br />
      핀다이렉트샵 사전 예약 알림 신청자가 챙길 수 있는 혜택은 총 6가지이다. △아이폰13 시리즈 3만 원 할인권 △자석형 무선 충전기
      ‘맥 세이프(MagSafe)’ △카카오 쇼핑포인트 12만점 △웰컴 기프트 2종(어댑터, 폰케이스)을 비롯하여, △출고 하루 지연 시 1일 1만 원
      상당의 신세계백화점 상품권 보상(최대 20만원 상당) △사용하던 스마트폰 반납 시 최대 20만원 추가 보상 등 아이폰 유저의 취향을
      반영한 보상 혜택도 풍부하다.
      <br />
      <br />
      특히 아이폰13 시리즈 3만원 할인권은 알림 신청 이후 사전 예약을 하지 않더라도 백퍼센트 지급되는 특전이다. 이를 위해서는
      핀다이렉트샵 홈페이지에서 사전 예약 알림 신청과 함께 자신의 카카오톡 플러스 친구에 &lt;핀다이렉트샵&gt; 채널을 추가하면
      끝이다. 이외 나머지 혜택은 알림을 통해 사전예약을 완료하면 자동으로 지급된다.
      <br />
      <br />
      출고 하루 지연 시 1일 1만 원 상당의 백화점 상품권 보상 제도도 파격적이다. 최대 20만원 상당의 보상까지 지원하는 이 혜택은
      하루라도 빨리 신형 휴대폰을 받고자 하는 아이폰 유저들의 특성을 반영했다. 중고폰 추가 보상 혜택 역시 사용하던 스마트폰을
      반납하면 시세보다 20만 원을 추가하여 지원하는 특별 보상 제도로, 이용자 혜택을 강화하기 위해 특별 구성됐다.
      <br />
      <br />
      사전 예약 알림 신청은 핀다이렉트샵 홈페이지(https://www.pindirectshop.com) 내 ‘이벤트’ 페이지에서 가능하다.
      <br />
      <br />
      스테이지파이브는 지난 해부터 아이폰 사전 예약 알림 서비스를 진행해왔다. 그동안 신형 아이폰을 하루 빨리 받아보고 싶어하는
      애플 마니아들의 뜨거운 성원을 받아온 만큼 이번 시리즈에도 ‘빠르고, 싸고, 특별한’ 사전 알림 서비스 및 혜택으로 ‘애플빠’
      마니아를 공략할 예정이다.
      <br />
      <br />
      핀다이렉트샵 아이폰 13 시리즈 사전 예약 알람 신청은 30일 종료된다. 아이폰 13 시리즈 사전 예약은 14일(미국 현지시각) 발표될
      애플 공식 일정에 따라 진행할 예정이다.
      <br />
      <br />
      | 합리적 MZ에 안성맞춤, 핀다이렉트샵
      <br />
      <br />
      핀다이렉트샵은 세련되고 편리한 UX 설계로 MZ세대에 좋은 반응을 얻고 있는 비대면 통신 가입 플랫폼이다. 카카오톡 채널을
      비롯하여 웹과 모바일을 통한 접근이 가능하며, 카카오페이를 통한 본인인증 등 가입 절차, 배송 및 상담 등 구매 이후 절차 역시
      카카오톡으로 간편하게 이용할 수 있다. 이곳저곳 흩어진 포인트를 한 번에 모아 선 할인 받을 수 있는 것도 강점이다.
      <br />
      <br />
      뿐만 아니라 핀다이렉트샵 내에서는 통신 관련 상품이 한 데 모여 있어, 합리적 소비를 추구하는 소비자들이 빠르고 쉽게 구매하기에
      안성맞춤이다. 최신상 스마트폰과 합리적인 요금이 매칭된 기기결합 요금제, 자급제폰과 찰떡궁합인 알뜰폰(MVNO) 유심 요금제 등
      다양한 종류의 통신 요금제도 간편하게 비교할 수 있다. 신한 플러스, 멜론, SPC 요금제 등 이종산업간 결합한 핀다이렉트샵 만의
      제휴요금제도 준비돼 있다. 뿐만 아니라 최신 5G 스마트폰은 물론, 2단계 품질 검수를 거치는 ‘핀다이렉트케어’ 중고폰까지 한 곳에
      모여 있어 고객 선택권도 한층 강화됐다.
      <br />
      <br />
      <br />
    </p>
  </div>
)

const press15 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브 제휴CR실"
      writer="커뮤니케이션팀 pr@stagefive.com "
      date="2021년 9월 14일 즉시 배포 가능"
      pageInfo="*총 2매 / 첨부 1매"
      title="“빠르고, 싸고, 특별하게…아이폰13 사전예약 알림 받으세요”"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press15
